
import { Vue, Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";
import { Pedido } from "@/core/models/compras";
import { PedidoService } from "@/core/services/compras/PedidoService";
import { Despesa } from "@/core/models/financeiro";
import { PessoaService } from "@/core/services/cadastros";
import { TipoProdutoService } from "@/core/services/almoxarifado";
import { DespesaService } from "@/core/services/financeiro";
import { EnumOrigemDespesa } from "@/core/models/financeiro/OrigemDespesa";

@Component
export default class ListaPedido extends mixins(Vue, ListPage) {
  item = new Pedido();
  service = new PedidoService();

  despesa = new Despesa();
  filter = new Modelo();
  totalLista = 0;

  titulo = "Pedidos de compras";
  subTitulo ="Lista dos pedidos de compras cadastrados no sistema";

  pessoas = [];
  pedidos = [];
  tipos = []; 

  dialogReceberPedido:boolean = false;
  dialogCadastro:boolean = false;
  dialogRequisicao:boolean = false;
  dialogVisualizar:boolean = false;
  dialogXml:boolean = false;
  loading:boolean = false;
  sheet:boolean = false;

  options: any = {  
    itemsPerPage: 15,
  };

  headers: any[] = [
    { text: "Ações", value: "actions", sortable: false },
    { text: "Nº Pedido", value: "orcamentoId", type:'number' },
    { text: "Nº Despesa", value: "despesaId", type:'number' },
    { text: "Ident. Orçamento", value: "orcamento.descricao" },
    { text: "Tipo", value: "tipo.nome" },
    { text: "Fornecedor", value: "fornecedor.nome" },
    { text: "Sit. Material", value: "material", type:'text', sortable: false },
    { text: "Sit. Financeiro", value: "financeiro", type:'text', sortable: false},
    { text: "Conferente", value: "conferente" },
    { text: "Data Emissão", value: "dataEmissao", type:'date' },
    { text: "Data Entrega", value: "dataEntrega", type:'date' },
  ];

  Consultar(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.ListarComFiltro(page,itemsPerPage,sortBy,sortDesc,search,columns, this.filter, 'Itens, Fornecedor, Tipo,Orcamento.Fornecedores, Despesa').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
        this.loading = false;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
    this.sheet = false;
  }
 
  @Watch("options", { deep: true })
  Atualizar() {
    const { page, itemsPerPage, sortBy, sortDesc } = this.options;
    this.loading = true;

      let paramTemp = ''; 

        if (this.filter.orcamentoId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `orcamentoId eq ${this.filter.orcamentoId}`;
        }
 
        if(this.filter.fornecedorId){
            if(paramTemp){ paramTemp += ' and '; }
            paramTemp += `fornecedorId eq ${this.filter.fornecedorId}`;
        }

        if(this.filter.conferente){
            if(paramTemp){ paramTemp += ' and '; }
            paramTemp += `conferente eq '${this.filter.conferente}'`;
        }
        if(this.filter.tipoId){
            if(paramTemp){paramTemp += ' and '; }
            paramTemp += `tipoId eq ${this.filter.tipoId}`;
        }

        if(this.filter.dataEmissao){
          if(paramTemp){paramTemp += ' and '; }
          paramTemp += `dataEmissao eq ${this.filter.dataEmissao} `;
        }
        if(this.filter.dataEntrega){
          if(paramTemp){paramTemp += ' and '; }
          paramTemp += `dataEntrega eq ${this.filter.dataEntrega} `;
        }
           
        if(this.filter.descricao){
          if(paramTemp){paramTemp += ' and '; }
          paramTemp += `orcamento/descricao eq '${this.filter.descricao}' `;
        }

    this.service.Listar(page, itemsPerPage, sortBy.length == 0 ? ['orcamentoId'] : sortBy, sortBy.length == 0 ? [true] : sortDesc, this.search, this.headers, paramTemp,'', '', 'Itens, Fornecedor, Tipo,Orcamento.Fornecedores, Despesa').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
        this.pedidos = [];
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }) 
    .finally(() => (this.loading = false));
  }

  ReceberPedido(item: Pedido) {
    this.service.ObterPorId(item.id, 'Empresa, Orcamento, Tipo, Itens.Produto,Itens.Situacao').then(
    res => {
      this.item = res.data 
      this.dialogReceberPedido = true;
    },
    (err) => {
      if (!err.response) {
        this.$swal("Aviso", "Não foi possível acessar a API", "error");
      } else if (err.response.status == 403) {
        this.$swal("Aviso", err.response.data.message, "warning" );
      } else {
        this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
      }
    }); 
  } 
 
  GerarDespesa() {
    if(!this.pedidos.some(x => x)){
      this.$swal("Aviso", "É necessário selecionar um ou mais pedido para gerar a Despesa!", "warning");
      return;
    }

    if(this.pedidos.some((x:any) => x.despesaId)){
      this.$swal("Aviso", "Já possui despesa cadastrado em um dos pedidos selecionados!", "warning");
      return;
    }

    this.despesa = new Despesa();
      this.pedidos.forEach((x:any) =>
        this.despesa.valor += x.itens.reduce((x:any, y:any) => x + y.total, 0) + x.orcamento.fornecedores.reduce((x:any, y:any) => x + y.frete, 0)
      ); 
    this.despesa.origemId = EnumOrigemDespesa.PedidoCompra;
    this.dialogCadastro = true;
  }

  VisualizarDespesa(item){
    new DespesaService().ObterPorId(item.despesa!.id, 'Parcelas, Classificacoes, Parcelas.Situacao').then(
      (res) => {
        this.despesa = res.data;
        this.dialogCadastro = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      })
    }

  Requisicao(item) { 
    this.service.ObterPorId(item.id, 'Empresa, Orcamento, Tipo, Itens.Produto.AlmoxarifadoItens, Itens.Situacao').then(
      res => {
        this.item = res.data 
        this.dialogRequisicao = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
  }
 
  ImportarXml(item){
    this.service.ObterPorId(item.id, 'Empresa, Orcamento, Tipo, Fornecedor,SituacaoFrete, Itens.Produto,Itens.Situacao, Itens.Produto.Fornecedores').then(
      res => {
        this.item = res.data
        this.dialogXml = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      });
  }

  Detalhar(item) {
    this.service.ObterPorId(item.id, 'Empresa, Orcamento, Tipo, Fornecedor,SituacaoFrete, Itens.Produto,Itens.Situacao').then(
      res => {
        this.item = res.data
        this.dialogVisualizar = true;
      },
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        }
      }); 
  }

  situacaoColor(item) {
    if (item.situacaoGeralPedidoItensId == 1) return "red lighten-2"; //Pendente
    else if (item.situacaoGeralPedidoItensId == 2) return "yellow darken-2"; // Parcial
    else if (item.situacaoGeralPedidoItensId == 3) return "teal lighten-2"; // Finalizado
  }

  situacaoColorFinanceiro(item) {
    if(item.despesa ? item.despesa.isMultiplosPedidos == true :'') return "purple lighten-2";
    if (item.despesa != null) return "teal lighten-2";
    else return "red lighten-2"; 
  }

  mounted() {
    new TipoProdutoService().ListarTudo().then(
      res => this.tipos = res.data.items
    )

    new PessoaService().ListarTudo().then(
      res =>{
        this.pessoas = res.data.items
      }
    )
  }
}

class Modelo {
  orcamentoId:number = 0;
  fornecedorId:number = 0
  tipoId:number = 0;
  descricao:string = "";
  conferente:string = "";
  dataEmissao:string = "";
  dataEntrega:string  = "";    
}
